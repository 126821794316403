import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27.06"
      height="27.81"
      viewBox="0 0 27.06 27.81"
    >
      <path d="m19.12,27.81H7.94c-4.38,0-7.94-3.56-7.94-7.95V7.94C0,3.56,3.56,0,7.94,0h11.17c4.38,0,7.94,3.56,7.94,7.94v11.92c0,4.38-3.56,7.95-7.94,7.95ZM7.94,2.04c-3.26,0-5.91,2.65-5.91,5.91v11.92c0,3.26,2.65,5.91,5.91,5.91h11.17c3.26,0,5.91-2.65,5.91-5.91V7.94c0-3.26-2.65-5.91-5.91-5.91H7.94Zm5.59,19.22c-4.07,0-7.39-3.31-7.39-7.39,0-2.01.83-3.96,2.27-5.36,1.4-1.36,3.24-2.08,5.16-2.03,4.56.13,7.45,3.04,7.35,7.42h0c-.09,4.13-3.34,7.36-7.39,7.36Zm-.17-12.29c-1.2,0-2.33.47-3.22,1.33-.96.93-1.51,2.24-1.51,3.58,0,2.7,2.2,4.9,4.9,4.9s4.84-2.16,4.9-4.93h0c.06-2.96-1.78-4.78-4.94-4.87-.04,0-.09,0-.13,0Zm7.74-4.42c-.95,0-1.72.77-1.72,1.72s.77,1.72,1.72,1.72,1.72-.77,1.72-1.72-.77-1.72-1.72-1.72Z"/>
    </svg>
  );
}

export default Instagram;